/* Text utilities */
.text-primary {
	color: #ff3d00 !important;
}

.text-underline {
	text-decoration: underline;
	text-decoration-color: #ff3d00;
}

.first-letter{
	font-size: 4.5rem;
}

/* Button utilities */
.btn-primary,
.btn-primary:hover,
.btn-primary:active {
	background-color: #ff3d00;
	border: #ff3d00;
}

.btn-link,
.btn-link:hover,
.btn-link:active
.btn-link:focus {
	color: #ff3d00 !important;
	text-decoration: none !important;
	outline: none !important;
  box-shadow: none !important;
}

.btn-link.btn-lg {
	font-size: 1.75rem;
}

/* Backgrounds */
.bg-alt {
	background-color: #dee2e6;
}

.bg-main-navbar {
	background-color: #6c757d;
}

/*Navbar */
li.nav-item:hover{
	cursor: pointer;
}

.active-nav {
	border-bottom: 1px solid #ff3d00;
}

/* Other */
.dimension-list-item {
	font-size: 1rem;
}

#follow-button {
	font-size: 3rem !important;
}