.Tagline{
	font-weight: 300;
	font-size: 1.5rem;
}

.Link,
.Link:hover
.Link:active {
	color: #212529 !important;
}

.Link:hover{
	text-decoration-color: #ff3d00;
}