.FeatureTable {
	overflow-x: scroll;
}

.TableWrapper{
	min-width: 720px;
}

.HeaderRow {
	border-top: 4px solid #fff;
	border-bottom: 4px solid #fff;
	background-color: #6c757d;
	color: #fff; 
}

.HeaderTitle {
	margin: 0;
	font-size: 1.25rem;
}

@media only screen and (max-width: 992px) {
	.HeaderTitle {
		font-size: 1rem;
	}
}

.BorderRight {
	border-right: 1px solid #fff;
}

.GroupRow {
	border-bottom: 4px solid #fff;
}

.GroupTitle {
	margin: 1rem 0;
}

.FeatureRow {
	border-bottom: 1px solid #fff;
}

.ExcludeBg {
	background-color: #f4cccc; 
}

.IncludeBg {
	background-color: #d9ead3; 
}

.FeatureTitle {
	margin: 0;
	font-size: 1rem;
	font-weight: 400;
}

.Example {
	margin: 0;
	color: #6c757d;
	font-size: 0.8rem;
}

.Score {
	margin: 0;
}
