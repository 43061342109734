.Title {
	font-size: 2.4rem;
	margin-bottom: 0;
}

.Logo {
	height: 80px;
	width: auto;
}

.Explanation {
	font-size: 1.25rem;
	font-weight: 300;
}

@media (max-width: 768px){	
	.FeatureGroups {
		column-count: 1;
	}
	.FeatureGroups div {
	  display: inline-block;
		width: 100%;
	}
}

.Rectangle {
	height: 50px;
	width: 80px;
	margin-right: 0.2rem;
	border-radius: 0.25rem;
	border: 1px solid #fff;
}

.ExcludeBg {
	background-color: #f4cccc; 
}

.IncludeBg {
	background-color: #d9ead3; 
}

.Benefit {
	margin: 1rem;
	font-size: 1.25rem;
	font-weight: 300;
}

.ConclusionPoint{
	font-size: 1.5rem;
	font-weight: 300;
}