.ProfilePic {
	height: 200px;
	width: 200px;
	margin: 2rem 0;
}

.AboutP {
	font-size: 1.5rem;
	font-weight: 300;
	margin-bottom: 1.5rem;
}

.TwitterButton,
.TwitterButton:hover 
.TwitterButton:active{
	background-color: #1da1f2 !important;
	border: #1da1f2;
	font-size: 1.75rem;
	padding: 0.25rem 1rem;
	border-radius: 0.5rem;
}

.TwitterButton:hover {
	background-color: #2f8de7 !important;
}