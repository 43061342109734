.Title {
	font-size: 2.4rem;
}

.Answer {
	font-size: 2rem;
	font-weight: 400;
	margin: 1.5rem 0;
}

.MainPoint {
	font-size: 1.5rem;
	font-weight: 300;
}

.CupPosition {
	margin-top: 2.5rem;
	width: 100%;
	height: auto;
}

.Credit {
	color: #6c757d;
	margin-bottom: 2.5rem;
}

.Link {
	margin: 0 0.2rem;
	color: #6c757d;
	text-decoration: underline;
}

.Link:hover {
	color: #6c757d;
}