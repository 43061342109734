.VidWrapper {
	position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}

.Video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.Close {
	position: absolute;
	top: 10px;
	right: 10px;
}

.Close:hover {
	cursor: pointer;
}