.Logo {
	display: flex;
	align-items: center;
	color: #fff;
	font-weight: 1.4rem;
}

.Cup {
	width: 40px;
	height: auto;
	margin-right: 0.3rem;
}

.LogoAccent {
	font-weight: 600;
	color: #ff3d00;
}